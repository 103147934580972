<template>
  <el-dialog
    :title="isEdit === true ? '编辑' : '新增'"
    :visible.sync="show"
    width="400px"
    center
  >
    <el-form
      :model="form"
      v-if="show"
      :label-width="formLabelWidth"
      label-position="left"
      ref="form"
      :rules="rules"
    >
      <!--      <el-form-item label="上级id" style="width: 100%">
                    <el-select v-model="form.cat_id" prop="account_type">
                      <el-option
                          v-for="item in cateData"
                          :key="item.id"
                          :label="item.cat_name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>-->
      <el-form-item label="分类名称" prop="cat_name">
        <el-input v-model="form.cat_name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="权重" prop="weight">
        <el-input v-model.number="form.weight" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="排序" prop="sort_order">
        <el-input
          v-model.number="form.sort_order"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="是否多选" prop="is_multiple">
        <el-switch v-model="form.is_multiple"></el-switch>
      </el-form-item>

      <el-form-item label="是否必选" prop="required">
        <el-switch v-model="form.required"></el-switch>
      </el-form-item>

      <el-form-item label="是否启用" prop="enable">
        <el-switch v-model="form.enable"></el-switch>
      </el-form-item>

      <el-form-item label="是否折叠" prop="isFold">
        <el-switch v-model="form.isFold"></el-switch>
      </el-form-item>

      <el-form-item label="up主展示" prop="tag_name">
        <el-switch v-model="form.is_show"></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";

export default {
  name: "addForm",
  data() {
    return {
      formLabelWidth: "100px",
      form: {
        // cat_id:'',
        cat_name: "",
        weight: "",
        sort_order: "",
        is_multiple: true,
        is_show: true,
        required: true,
        enable: true,
        isFold: true,
      },
      isEdit: false,
      show: false,
      cateData: [],
      rules: {
        cat_name: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
        weight: [{ required: true, message: "请输入权重", trigger: "blur" }],
        sort_order: [
          { required: true, message: "请输入排序", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit() {
      const funName = this.isEdit ? "updateData" : "addData";
      this.$refs.form.validate((valid) => {
        valid ? this[funName]() : "";
      });
    },
    async updateData() {
      const { data,msg } = await API.updateCate(this.form)
        .then((res) => {
          this.$message.success("编辑成功");
          this.show = false;
          this.$parent.getList();
          console.log(res, data);
        })
        .catch((err) => {
          this.$message.error(msg);
          console.log(err);
        });
    },
    editData(data) {
      this.form = data;
      this.isEdit = true;
      this.show = true;
    },
    clearData() {
      this.form = {
        cat_name: "",
        weight: "",
        sort_order: "",
        is_multiple: true,
        is_show: true,
        required: true,
        enable: true,
        isFold: true,
      };
    },
    addFormData() {
      this.isEdit = false;
      this.clearData();
      this.show = true;
      this.getList();
    },
    async addData() {
      const { data,msg } = await API.createCate(this.form)
        .then((res) => {
          this.$message.success("创建成功");
          this.show = false;
          this.$parent.getList();
          console.log(res, data);
        })
        .catch((err) => {
          this.$message.error(msg);
          console.log(err);
        });
    },
    getList() {
      let params = {
        page: 1,
        size: 1000,
      };
      API.getCateList(params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.cateData = res.data.data;
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.cateData = [];
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    created() {
      this.getList();
    },
  },
};
</script>

<style scoped>
</style>
